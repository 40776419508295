import React, { useState, useEffect } from "react";
import { useCalendarContext } from "../context/CalendarContext";

function Tasks({
  selectedYear,
  selectedMonth,
  selectedDay,
  months,
  handleDayClick,
}) {
  const { tasks, changeIsVisibleForm, getDayOfWeek, language } =
    useCalendarContext();

  const [filteredMonthTasks, setFilteredMonthTasks] = useState([]);
  const [filteredDayTasks, setFilteredDayTasks] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  const dayName = getDayOfWeek(selectedYear, selectedMonth, selectedDay);

  const selectDay = (day) => handleDayClick(day);

  useEffect(() => {
    const filterMonthTasks = () => {
      const filter = tasks.filter(
        (task) =>
          (task.year === selectedYear || task.repeat === "everyyear") &&
          task.month === selectedMonth + 1
      );

      setFilteredMonthTasks(filter);
    };

    const filterDayTasks = () => {
      const filter = tasks.filter(
        (task) =>
          (task.year === selectedYear || task.repeat === "everyyear") &&
          task.month === selectedMonth + 1 &&
          task.day === selectedDay
      );

      setFilteredDayTasks(filter);
    };

    filterMonthTasks();
    filterDayTasks();

    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [selectedDay, selectedMonth, selectedYear, tasks]);

  return (
    <div className="pad-x">
      <div className="content-grid">
        <div className="content month-content">
          <div className="day-info">
            <h2 className="day-title">
              {months[selectedMonth].fullName}
              <span className="month-day">({selectedYear})</span>
            </h2>
          </div>
          <div className="tasks">
            {filteredMonthTasks.length > 0 ? (
              filteredMonthTasks
                .sort((a, b) =>
                  a.day === b.day
                    ? new Date(
                        `${a.year}/${a.month}/${
                          a.day < 10 ? "0" + a.day : a.day
                        } ${a.time}`
                      ) -
                      new Date(
                        `${b.year}/${b.month}/${
                          b.day < 10 ? "0" + b.day : b.day
                        } ${b.time}`
                      )
                    : a.day - b.day
                )
                .map((task) => (
                  <div
                    key={task.id}
                    onClick={() => selectDay(task.day)}
                    className={`task-item ${
                      task.status === "done" ? "done" : ""
                    }`}
                  >
                    <div className="task-info">
                      <div className="task-time">
                        <div className={`time-dot ${task.color}`}></div>
                        {`${task.day < 10 ? "0" + task.day : task.day}.${
                          task.month < 10 ? "0" + task.month : task.month
                        }.${task.year}`}
                      </div>
                      <p className="task-text month-text">{task.task}</p>
                    </div>
                    <div className="arrows">
                      <i className="animation bi bi-chevron-right"></i>
                      <i className="animation bi bi-chevron-right"></i>
                      <i className="bi bi-chevron-right"></i>
                    </div>
                  </div>
                ))
            ) : (
              <div className="error-msg">
                {language === "en"
                  ? `You don't have any task for the month of ${months[selectedMonth].fullName} in ${selectedYear}`
                  : `${selectedYear} ${months[selectedMonth].fullName} ayı için herhangi bir göreviniz yok.`}
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <div className="day-info">
            <h2 className="day-title">
              {dayName}
              <span className="month-day">
                {language === "en"
                  ? `(${months[selectedMonth].fullName} ${selectedDay})`
                  : `(${selectedDay} ${months[selectedMonth].fullName})`}
              </span>
            </h2>
            <button
              onClick={() => changeIsVisibleForm(0)}
              type="button"
              className="add-btn"
            >
              <i className="bi bi-plus-circle-dotted"></i>
              {language === "en" ? "Add New" : "Yeni Ekle"}
            </button>
          </div>
          <div className="tasks">
            {filteredDayTasks.length > 0 ? (
              filteredDayTasks
                .sort(
                  (a, b) =>
                    new Date(
                      `${a.year}/${a.month}/${
                        a.day < 10 ? "0" + a.day : a.day
                      } ${a.time}`
                    ) -
                    new Date(
                      `${b.year}/${b.month}/${
                        b.day < 10 ? "0" + b.day : b.day
                      } ${b.time}`
                    )
                )
                .map((task) => {
                  const taskTime = task.time.split(":");
                  const taskDate = new Date(
                    selectedYear,
                    selectedMonth,
                    selectedDay,
                    taskTime[0],
                    taskTime[1],
                    0
                  );
                  return (
                    <div
                      key={task.id}
                      onClick={() => changeIsVisibleForm(task.id)}
                      className={`task-item  ${
                        taskDate < currentDate ? "past" : task.color
                      } ${task.status === "done" ? "done" : ""}`}
                    >
                      <div className="task-info">
                        <div className="task-time">
                          {task.time}
                          <span className="time-past">
                            {`${
                              taskDate < currentDate
                                ? language === "en"
                                  ? "(past)"
                                  : "(geçmiş)"
                                : ""
                            }`}
                          </span>
                        </div>
                        <p className="task-text">{task.task}</p>
                      </div>
                      <div className="arrows">
                        <i className="animation bi bi-chevron-right"></i>
                        <i className="animation bi bi-chevron-right"></i>
                        <i className="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="error-msg">
                {language === "en"
                  ? `You don't have any missions for ${dayName}, ${months[selectedMonth].fullName} ${selectedDay}`
                  : `${selectedDay} ${months[selectedMonth].fullName} ${dayName} günü için herhangi bir göreviniz yok.`}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tasks;
