import { createContext, useContext, useEffect, useState } from "react";

const CalendarContext = createContext();

function Provider({ children }) {
  const [language, setLanguage] = useState("en");
  const [isVisibleForm, setIsVisibleForm] = useState(false);
  const [editTask, setEditTask] = useState();
  const [tasks, setTasks] = useState([]);

  const changeLanguage = () =>
    setLanguage((prev) => (prev === "en" ? "tr" : "en"));

  const changeIsVisibleForm = (index) => {
    const findTaskIndex = tasks.find((task) => task.id === index);
    setEditTask(findTaskIndex);
    setIsVisibleForm((prev) => !prev);
  };

  const getDayOfWeek = (year, month, day) => {
    const date = new Date(year, month, day);
    const options = { weekday: "long" };
    const langCode = language === "en" ? "en-US" : "tr-TR";
    return date.toLocaleDateString(langCode, options);
  };

  useEffect(() => {
    getTasksFromLocalStorage();
  }, []);

  const getTasksFromLocalStorage = () => {
    let data= [];
    if (localStorage.getItem("calendar")) {
      data = JSON.parse(localStorage.getItem("calendar"));
    } else {
      localStorage.setItem("calendar", JSON.stringify(data));
      data = JSON.parse(localStorage.getItem("calendar"));
    }
    setTasks(data);
  };

  const addNewTask = (taskItem) => {
    const newTasks = [...tasks, taskItem];
    localStorage.setItem("calendar", JSON.stringify(newTasks));
    getTasksFromLocalStorage();
  };

  const removeTask = (removeId) => {
    if (
      window.confirm(
        language === "en"
          ? "Are you sure you want to remove"
          : "Görevi silmek istediğinize emin misiniz?"
      )
    ) {
      const newTasks = tasks.filter((task) => task.id !== removeId);
      localStorage.setItem("calendar", JSON.stringify(newTasks));
      getTasksFromLocalStorage();
    }
  };

  const updateTask = (taskItem) => {
    const newTasks = tasks.map((task) => {
      if (task.id === taskItem.id) {
        return {
          id: taskItem.id,
          task: taskItem.task,
          year: taskItem.year,
          month: taskItem.month,
          day: taskItem.day,
          color: taskItem.color,
          time: taskItem.time,
          status: taskItem.status,
        };
      }
      return task;
    });
    localStorage.setItem("calendar", JSON.stringify(newTasks));
    getTasksFromLocalStorage();
  };

  const sharedValuesAndMethods = {
    tasks,
    isVisibleForm,
    language,
    changeLanguage,
    changeIsVisibleForm,
    getDayOfWeek,
    getTasksFromLocalStorage,
    addNewTask,
    removeTask,
    updateTask,
    editTask,
  };
  return (
    <CalendarContext.Provider value={sharedValuesAndMethods}>
      {children}
    </CalendarContext.Provider>
  );
}

const useCalendarContext = () => useContext(CalendarContext);
export { Provider, useCalendarContext };
export default CalendarContext;
