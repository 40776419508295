import Tasks from "./components/Tasks";
import TaskForm from "./components/TaskForm";
import { useState } from "react";
import { useCalendarContext } from "./context/CalendarContext";
import EnglandLogo from "./img/england.svg";
import TurkeyLogo from "./img/turkey.svg";
import "./scss/index.scss";

function App() {
  const { tasks, language, changeLanguage } = useCalendarContext();

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDay, setSelectedDay] = useState(currentDay);

  const selectToday = () => {
    setSelectedYear(currentYear);
    setSelectedMonth(currentMonth);
    setSelectedDay(currentDay);
  };

  const months = {
    en: [
      { fullName: "January", shortName: "Jan" },
      { fullName: "February", shortName: "Feb" },
      { fullName: "March", shortName: "Mar" },
      { fullName: "April", shortName: "Apr" },
      { fullName: "May", shortName: "May" },
      { fullName: "June", shortName: "Jun" },
      { fullName: "July", shortName: "Jul" },
      { fullName: "August", shortName: "Aug" },
      { fullName: "September", shortName: "Sep" },
      { fullName: "October", shortName: "Oct" },
      { fullName: "November", shortName: "Nov" },
      { fullName: "December", shortName: "Dec" },
    ],
    tr: [
      { fullName: "Ocak", shortName: "Oca" },
      { fullName: "Şubat", shortName: "Şub" },
      { fullName: "Mart", shortName: "Mar" },
      { fullName: "Nisan", shortName: "Nis" },
      { fullName: "Mayıs", shortName: "May" },
      { fullName: "Haziran", shortName: "Haz" },
      { fullName: "Temmuz", shortName: "Tem" },
      { fullName: "Ağustos", shortName: "Ağu" },
      { fullName: "Eylül", shortName: "Eyl" },
      { fullName: "Ekim", shortName: "Eki" },
      { fullName: "Kasım", shortName: "Kas" },
      { fullName: "Aralık", shortName: "Ara" },
    ],
  };

  const handlePreviousYear = () => setSelectedYear((prevYear) => prevYear - 1);

  const handleNextYear = () => setSelectedYear((prevYear) => prevYear + 1);

  const handleMonthClick = (index) => setSelectedMonth(index);

  const handleDayClick = (index) => setSelectedDay(index);

  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();

  const renderDays = () => {
    const totalDays = getDaysInMonth(selectedYear, selectedMonth);
    const days = Array.from({ length: totalDays }, (_, index) => index + 1);

    return days.map((day, index) => {
      let fill = tasks.find(
        (task) =>
          task.year === selectedYear &&
          task.month === selectedMonth + 1 &&
          task.day === day
      );

      return (
        <button
          key={index}
          onClick={() => handleDayClick(index + 1)}
          className={`day-btn ${index + 1 === selectedDay ? "active" : ""} ${
            fill ? "fill" : ""
          } `}
        >
          <span className="day">{day}</span>
          <span className="day-fill"></span>
        </button>
      );
    });
  };
  return (
    <div className="app-container">
      <div className="calendar-box">
        <header className="header pad-x">
          <h1 className="title">{language === "en" ? "Calendar" : "Takvim"}</h1>
          <div className="header-buttons">
            <button onClick={selectToday} className="header-button">
              {language === "en" ? "Today" : "Bugün"}
            </button>
            <button onClick={changeLanguage} className="header-button">
              <img
                className="country-logo"
                src={language === "en" ? TurkeyLogo : EnglandLogo}
                alt="country logo"
              />
            </button>
          </div>
        </header>
        <div className="dates">
          <div className="date-times pad-x">
            <div className="date-year">
              <button className="year-btn" onClick={handlePreviousYear}>
                <i className="bi bi-chevron-left"></i>
              </button>
              <span>{selectedYear}</span>
              <button className="year-btn" onClick={handleNextYear}>
                <i className="bi bi-chevron-right"></i>
              </button>
            </div>
            <div className="date-months">
              <div className="months">
                {months[language].map((month, index) => {
                  let fill = tasks.find(
                    (task) =>
                      (task.year === selectedYear ||
                        task.repeat === "everyyear") &&
                      task.month === index + 1
                  );
                  return (
                    <button
                      key={index}
                      className={`month ${
                        index === selectedMonth ? "active" : ""
                      } ${fill ? "fill" : ""}`}
                      onClick={() => handleMonthClick(index)}
                    >
                      <span className="month-name">{month.shortName}</span>
                      <span className="month-fill"></span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="date-days">
            <div
              className="days pad-x"
              style={{
                gridTemplateColumns: `repeat(${getDaysInMonth(
                  selectedYear,
                  selectedMonth
                )}, 1fr)`,
              }}
            >
              {renderDays()}
            </div>
          </div>
        </div>
        <Tasks
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          selectedDay={selectedDay}
          months={months[language]}
          handleDayClick={handleDayClick}
        />
        <TaskForm
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          selectedDay={selectedDay}
          months={months[language]}
        />
      </div>
    </div>
  );
}

export default App;
