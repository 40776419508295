import React, { useState, useEffect } from "react";
import { useCalendarContext } from "../context/CalendarContext";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";

function TaskForm({ selectedYear, selectedMonth, selectedDay, months }) {
  const {
    isVisibleForm,
    changeIsVisibleForm,
    editTask,
    getDayOfWeek,
    language,
    addNewTask,
    removeTask,
    updateTask,
  } = useCalendarContext();

  const colors = ["red", "blue", "green", "orange", "purple"];

  const pendingText = language === "en" ? "Pending" : "Bekliyor";
  const completedText = language === "en" ? "Completed" : "Tamamlandı";

  const [errors, setErrors] = useState({
    task: "",
    color: "",
    time: "",
    status: "",
  });

  const [taskText, setTaskText] = useState(editTask ? editTask.task : "");
  const [taskTime, setTaskTime] = useState(editTask ? editTask.time : "");
  const [taskColor, setTaskColor] = useState(editTask ? editTask.color : "");
  const [taskStatus, setTaskStatus] = useState(
    editTask ? editTask.status : "pending"
  );

  const dayName = getDayOfWeek(selectedYear, selectedMonth, selectedDay);

  const currentDate = new Date();
  const timeTask = taskTime.split(":");
  const taskDate = new Date(
    selectedYear,
    selectedMonth,
    selectedDay,
    timeTask[0],
    timeTask[1],
    0
  );

  useEffect(() => {
    if (editTask) {
      setTaskText(editTask.task);
      setTaskTime(editTask.time);
      setTaskColor(editTask.color);
      setTaskStatus(editTask.status);
    }
  }, [editTask]);

  const taskSchema = Yup.object().shape({
    task: Yup.string()
      .required(language === "en" ? "Task is required" : "Görev alanı gerekli")
      .max(
        100,
        language === "en"
          ? "Task cannot exceed 100 characters"
          : "Görev alanı 100 karakteri aşamaz"
      ),
    color: Yup.string().required(
      language === "en" ? "Color is required" : "Renk seçimi gerekli"
    ),
    time: Yup.string().required(
      language === "en" ? "Time is required" : "Saat seçimi gerekli"
    ),
    status: Yup.string().required(
      language === "en" ? "Status is required" : "Görev durumu seçimi gerekli"
    ),
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const uniqueId = uuidv4();

    const formData = {
      id: editTask ? editTask.id : uniqueId,
      task: taskText,
      time: taskTime,
      color: taskColor,
      status: taskStatus,
      day: selectedDay,
      month: selectedMonth + 1,
      year: selectedYear,
    };

    taskSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        initialStates();
        editTask ? updateTask(formData) : addNewTask(formData);
      })
      .catch((error) => {
        if (error.inner) {
          setErrors(
            error.inner.reduce((acc, curr) => {
              acc[curr.path] = curr.message;
              return acc;
            }, {})
          );
        }
      });
  };

  const initialStates = () => {
    setTaskText("");
    setTaskTime("");
    setTaskColor("");
    setTaskStatus("pending");
    setErrors({
      task: "",
      color: "",
      time: "",
      status: "",
    });
    changeIsVisibleForm(0);
  };

  const closePopup = (e) =>
    e.target.classList.contains("close-popup") ? initialStates() : null;

  const deleteTask = (taskId) => {
    removeTask(taskId);
    initialStates();
  };
  return (
    <div
      onClick={closePopup}
      className={isVisibleForm ? "form-area visible close-popup" : "form-area"}
    >
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-header">
          <h2 className="form-title">
            {editTask
              ? language === "en"
                ? "Edit Task"
                : "Görev Düzenle"
              : language === "en"
              ? "Add Task"
              : "Görev Ekle"}
          </h2>
          <div className="popup-buttons">
            {editTask ? (
              <button
                onClick={() => deleteTask(editTask.id)}
                type="button"
                className="popup-btn red"
              >
                <i className="bi bi-trash"></i>{" "}
                {language === "en" ? "Delete" : "Sil"}
              </button>
            ) : null}
            <button type="button" className="popup-btn close-popup">
              <i className="bi bi-x"></i>{" "}
              {language === "en" ? "Close" : "Kapat"}
            </button>
          </div>
        </div>

        {taskDate < currentDate ? (
          <div className="error-msg">
            {language === "en"
              ? "This task is out of date"
              : "Bu görevin tarihi güncel değil"}
          </div>
        ) : null}
        <div className="input-box">
          <div className="form-info">
            <i className="bi bi-calendar-date"></i>
            {`${months[selectedMonth].fullName} ${selectedDay}, ${dayName} ${selectedYear}`}
          </div>
        </div>
        <div className="input-box">
          <label htmlFor="task">{language === "en" ? "Title" : "Görev"}</label>
          <textarea
            type="text"
            id="task"
            className="input text-input"
            value={taskText}
            placeholder={
              language === "en"
                ? "Please enter the task title"
                : "Lütfen görev içeriğini giriniz"
            }
            onChange={(e) => setTaskText(e.target.value)}
          ></textarea>
          {errors.task && <div className="error-msg">{errors.task}</div>}
        </div>

        <div className="input-box">
          <label htmlFor="time">{language === "en" ? "Time" : "Saat"}</label>
          <input
            type="time"
            id="time"
            className="input"
            value={taskTime}
            onChange={(e) => setTaskTime(e.target.value)}
          />
          {errors.time && <div className="error-msg">{errors.time}</div>}
        </div>

        <div className="input-box">
          <label htmlFor="color">{language === "en" ? "Color" : "Renk"}</label>
          <div className="color-buttons">
            {colors.map((color, index) => (
              <div
                key={index}
                className={
                  taskColor === color
                    ? `color-btn ${color} active`
                    : `color-btn ${color}`
                }
              >
                <input
                  type="radio"
                  id={color}
                  className="radio-input"
                  name="color"
                  value={color}
                  onChange={(e) => setTaskColor(e.target.value)}
                />
                <label className={color} htmlFor={color}></label>
              </div>
            ))}
          </div>
          {errors.color && <div className="error-msg">{errors.color}</div>}
        </div>

        {editTask ? (
          <div className="input-box">
            <label htmlFor="status">
              {language === "en" ? "Status" : "Durum"}
            </label>
            <div className="status-labels">
              <input
                type="radio"
                id="pending"
                className="radio-input"
                name="status"
                value="pending"
                checked={taskStatus === "pending"}
                onChange={(e) => setTaskStatus(e.target.value)}
              />
              <label htmlFor="pending" className="status-label">
                {pendingText}
                <span className="label-detail">Every day of the year</span>
              </label>
              <input
                type="radio"
                id="done"
                className="radio-input"
                name="status"
                value="done"
                checked={taskStatus === "done"}
                onChange={(e) => setTaskStatus(e.target.value)}
              />
              <label htmlFor="done" className="status-label">
                {completedText}
                <span className="label-detail">Görev tamamlandı</span>
              </label>
            </div>
            {errors.status && <div className="error-msg">{errors.status}</div>}
          </div>
        ) : null}

        <button
          type="submit"
          className={errors.task ? "submit-btn" : "submit-btn active"}
        >
          {editTask
            ? language === "en"
              ? "Update Task"
              : "Görevi Güncelle"
            : language === "en"
            ? "Add Task"
            : "Görev Ekle"}
        </button>
      </form>
    </div>
  );
}

export default TaskForm;
